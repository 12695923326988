.arrow {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  min-width: 40px;
  width: max-content;
  position: relative;
  line-height: 1.2rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  background: #f8f8f8;
  border-radius: 3px;
  font-weight: bold;
  text-align: center;
  color: #323540;
  transform: rotate(-50deg);
  transform-origin: top left;
}

.arrow:after {
  content: '';
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f8f8f8;
  border-left: 0;
  height: 0;
  width: 0;
  margin-left: -9px;
  left: 0;
}

.icon-div {
}
