body {
  font-family: 'Poppins', sans-serif;
}
samp {
  font-family: 'Poppins', sans-serif;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  font-family: 'Poppins', sans-serif;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  font-family: 'Poppins', sans-serif;
}
.ant-pagination-item {
  font-family: 'Poppins', sans-serif;
}
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  font-family: 'Poppins', sans-serif;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  font-family: 'Poppins', sans-serif;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: 'Poppins', sans-serif;
}
.ant-statistic-content {
  font-family: 'Poppins', sans-serif;
}
.ant-steps-item-icon {
  font-family: 'Poppins', sans-serif;
}
